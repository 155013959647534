import {Params} from '@angular/router';

import {UserPermissions} from '../../modules/permissions';
import {UserFleetPermission} from '../boats-fleet';
import {UserRoleType} from '../directories';

export class BasicNavItem {
  constructor(
    public readonly url: string | null,
    public readonly name: string,
  ) {
  }
}

export class NavItem extends BasicNavItem {
  constructor(
    public url: string | null,
    public name: string,
    public icon: string | null = null,
    public notice: string | null = null,
    public readonly permission: UserPermissions | UserPermissions[] | null = null,
    public additionalData: string | null = null,
    public readonly target: '_blank' | '_self' = '_self',
    public children: NavItem[] = [],
    public readonly featureToggle: string | null = null,
    public readonly disallowForCrewAgency: boolean | null = null,
  ) {
    super(url, name);
  }
}

export class HeaderNavItem extends NavItem {
  constructor(
    public url: string | null,
    public name: string,
    public icon: string | null = null,
    public notice: string | null = null,
    public readonly permission: UserPermissions | UserPermissions[] | null = null,
    public additionalData: string | null = null,
    public readonly target: '_blank' | '_self' = '_self',
    public readonly children: HeaderNavItem[] = [],
    public readonly isActive: boolean = false,
    public readonly activatedChildUrl: string | null = null,
    public readonly featureToggle: string | null = null,
    public readonly additionalClass: string | null = null,
    public readonly queryParams: Params = {},
  ) {
    super(url, name, icon, notice, permission, additionalData, target, children);
  }
}

export class RoleNavItem extends BasicNavItem {
  constructor(
    public url: string | null,
    public name: string,
    public icon: string | null = null,
    public roles: UserRoleType[] | null = null,
    public featureToggle: string | null = null,
    public queryParams: Params | null = null,
    public children: RoleNavItem[] = [],
  ) {
    super(url, name);
  }
}

export class ActivatedNavItem {
  constructor(
    public name: string,
    public url: string | null,
    public childName: string | null = null,
    public childUrl: string | null = null,
  ) {
  }
}

export class CvNavItem extends BasicNavItem {
  constructor(
    public readonly url: string | null,
    public readonly name: string,
    public isPublic = true,
  ) {
    super(url, name);
  }
}

export class SocialNavItem {
  constructor(
    public readonly url: string,
    public readonly iconClass: string,
  ) {
  }
}

export interface FleetNavItem extends BasicNavItem {
  readonly url: string | null;
  readonly name: string;
  readonly icon?: string;
  readonly permission?: UserFleetPermission | UserFleetPermission[];
  readonly featureToggle?: string;
  readonly target?: '_blank' | '_self';
}
