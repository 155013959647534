import {NavigationExtras} from '@angular/router';

export enum GeneralRoutes {
  PrivacyPolicy = 'privacy-policy',
  TermsAndConditions = 'terms-and-conditions',
  Auth = 'auth',
  PreferenceList = 'preference-list',
}

export enum BoatDetailsRoutes {
  Financial = 'financial',
}

export enum FinancialRoutes {
  BankStatements = 'bank-account-statements',
}

export enum ReturnUrl {
  SHORTCUTS = 'shortcuts',
  BOAT_DASHBOARD = 'dashboard',
  APA_REPORT = 'apa-report',
  CHARTER_PAGE = 'charter-data',
  CHARTER_LIST = 'charter-list',
  CHARTER_EXPENSES = 'charter-expenses',
  CHARTER_CALENDAR = 'charter-calendar',
  BOAT_CALENDAR = 'calendar',
  OUTSTANDING_PAYMENTS = 'outstanding-payments',
}

export enum CreateExpenseRoutes {
  Expense = 'simple-expense',
  SplitExpense = 'split-expense',
  OutstandingPayment = 'outstanding',
  CreditCardTopUp = 'credit-card-top-up',
  BankAccount = 'bank-account-income-outcome',
}

export enum HeaderRoutes {
  CrewDashboard = 'dashboard',
  SupplierProfile = 'supplier',
  BoatList = 'boats',
  FleetManagement = 'fleet-management',
  CompanySettings = 'company-settings',
  CreateCompany = 'auth/company/welcome',
  UserDownloads = 'downloads',
  UserSettings = 'settings',
  InvitedUsers = 'invite',
}

export enum UserHeaderRoutes {
  BoatList = 'boats',
  SupplierProfile = 'supplier',
  SupplierBusinessCards = 'supplier-cards',
  CrewCv = 'cv',
  ManagerProfile = 'profile',
  CrewDashboard = 'dashboard',
  CrewContracts = 'contracts',
  FleetManagement = 'fleet-management',
  Search = 'search',
}

export const AUTHORIZED_NAVIGATION_EXTRAS: NavigationExtras = {
  state: {
    authorized: true,
  },
};

export enum BoatSearchRoutes {
  Crew = 'crew',
  Offers = 'offers',
  Suppliers = 'suppliers',
  ServiceRequests = 'service-requests'
}

export enum ShareApaReportRoutes {
  ApaView = 'charter-apa-report',
  ApaAuth = 'charter-apa-report-auth'
}

export enum CharterPageRoutes {
  CharterData = 'charter-data',
  ApaReport = 'apa-report',
  CharterExpenses = 'charter-expenses',
  CharterTasks = 'charter-tasks',
  PositionHistory = 'position-history',
  PreferenceList = 'preference-list',
}

export enum UserPreferenceListRoutes {
  Auth = 'auth',
  List = 'list',
}
