export enum ConditionalStatus {
  YES = 1,
  NO = 2
}

export const booleanToConditionalStatus = (value: boolean | null | undefined): ConditionalStatus => !!value ? ConditionalStatus.YES : ConditionalStatus.NO;

export const conditionalStatusToBoolean = (status: ConditionalStatus) => status === ConditionalStatus.YES;

export type ConditionalItem = Readonly<{
  id: ConditionalStatus;
  name: string;
}>;
