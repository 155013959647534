export class Month {
  constructor(
    public readonly id: number,
    public readonly year: number,
    public readonly name: string,
    public readonly current: boolean,
    public readonly previous: boolean,
  ) {
  }
}
