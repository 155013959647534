import {SafeUrl} from '@angular/platform-browser';
import {Observable} from 'rxjs';

import {MediaUploadProgress} from '../cloud-media-uploader';

export enum UploadedFileType {
  Image = 'image',
  Document = 'document',
  Video = 'video'
}

export type UploadedFileMediaList = {
  [key in UploadedFileType]: string[];
};

export class UploadedFile {
  constructor(
    public readonly id: number | null,
    public readonly file: File | null,
    public readonly fileName: string | null,
    public readonly fileSize: number | null,
    public readonly path: string | SafeUrl | null,
    public readonly extension: string,
    public readonly type: UploadedFileType,
    public readonly originalFileName: string | null = null,
    public progress$: Observable<MediaUploadProgress | null> | null = null,
    public customFileName: string | null = null,
  ) {
  }
}

export class ErrorUploadFile {
  constructor(
    public readonly message: string,
    public readonly fileName: string | null = null,
  ) {
  }
}

export interface FileFromServer {
  url: string;
  id?: number;
  name?: string;
}
