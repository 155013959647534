export enum OverviewButtons {
  Annual = 'annual',
  Custom = 'custom',
  Monthly = 'monthly',
}

export class OverviewButton {
  constructor(
    public readonly mode: OverviewButtons,
    public readonly title: string,
  ) {
  }
}
